.logo {
  margin: auto;
}
.emergency {
  color: #dc3545 !important;
}
.emergency-button {
  background-color: #dc3545 !important;
  border: none;
}
.red {
  color: red;
}
